<template>
  <div class="lb-top-menu-wrapper" id="topNavigation">
    <div class="lb-top-menu">
      <div class="hidden lg:flex items-center gap-2 lg:gap-5 text-primary text-sm">
        <template v-for="(rate, key) in exchangeRates">
          <div class="space-x-1" v-if="rate">
            <span class="font-medium">
              {{getCurrencySymbol(key, true)}}
            </span>
            <span class="font-bold">{{rate}}</span>
          </div>
        </template>
      </div>

      <ul role="list" class="text-center justify-between items-center hidden sm:flex space-x-8">
        <li v-for="(item, index) in topMenu" :key="`ics-101${index}`">
          <NuxtLinkLocale :to="getLocaleString(item.href)" class="flex items-center justify-center text-center flex-wrap">
            <NuxtImg :src="item.custom?.image" height="16" width="16" class="mr-1.5" v-if="item.custom?.image" />
            <span>{{getLocaleString(item.name)}}</span>
          </NuxtLinkLocale>
        </li>
      </ul>

    </div>

    <ClientOnly>
      <SearchCombobox />
    </ClientOnly>
  </div>
</template>

<script setup>
const { locale } = useI18n()

const {status} = await useAuth()
const openCombobox = useState("openCombobox", () => false)

const storeConfig = useStoreConfig()
const { isB2BAccount, topMenu, exchangeRates } = storeToRefs(storeConfig)

</script>
